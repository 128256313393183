//alerts.less
	.alert .alert-link {
		font-weight: @alert-link-font-weight; color: @brand-primary !important;
	}

	.alert {
		> h1, > h2, > h3, > h4, > h5, >h5, > h6 {margin-top: 0 !important; color: #fff !important;}
		border-width: 1px;
		border-style: solid;
		padding: 32px;
		margin-bottom: 16px;
		i{
			font-size: @icon-font-size-base;
		}
		p {color: inherit !important}
	}

	.alert-inverse {
	  .alert-variant(@alert-inverse-bg; @alert-inverse-border; @alert-inverse-text);
	}

	.panel .alert {margin-bottom: 16px;}

	.alert-dismissable .close {
		right: 0;
		top: -1px;
	}


//thumbnails.less
	.thumbnail {
		margin-bottom: 0;
	}


//labels.less
	.label {
		padding: 0.225em 0.3em;
		font-family: @label-font-family;
		font-weight: @label-font-weight;
		border-radius: @label-border-radius;
		font-size: 88%;
		//line-height: 11px;
		min-height: 16px;
		min-width: 16px;
		text-transform: uppercase;
		display: inline-block;
		color: rgba(255, 255, 255, 0.8);
	}
	.list-group-item{
		span.icon{
			display: inline-block;
		    font-size: @icon-font-size-base;
		    height: 16px;
		    line-height: 16px;
		    margin-left: 0;
		    margin-right: 4px;
		    text-align: center;
		    width: 16px;
		}
	}


	// Colors
	.label-orange 			{.label-variant(@label-orange-bg);}
	.label-grape 			{.label-variant(@label-grape-bg);}
	.label-inverse 			{.label-variant(@label-inverse-bg);}
	.label-pink 			{.label-variant(@label-pink-bg);}
	.label-deep-purple 		{.label-variant(@label-deep-purple-bg);}
	.label-sky 				{.label-variant(@label-sky-bg); }
	.label-alizarin 		{.label-variant(@label-alizarin-bg);}
	.label-midnightblue 	{.label-variant(@label-midnightblue-bg);}
	.label-green 			{.label-variant(@label-green-bg);}
	.label-disabled			{.label-variant(@gray-lighter);}

	.badge {
		padding: 0px 5px;
		font-size: 85%; //@font-size-small;
		font-family: @badge-font-family;
		line-height: 16px;
		min-height: 16px;
		min-width: 16px;
		color: rgba(255, 255, 255, 0.8) !important;
	}

	// Colors
	// Contextual variations (based on label colors)

	.badge-primary  {.label-variant(@label-primary-bg);}
	.badge-deep-purple  {.label-variant(@label-deep-purple-bg);}
	.badge-pink  {.label-variant(@label-pink-bg);}
	.badge-success  {.label-variant(@label-success-bg);}
	.badge-info     {.label-variant(@label-info-bg);}
	.badge-warning  {.label-variant(@label-warning-bg);}
	.badge-danger   {.label-variant(@label-danger-bg);}
	.badge-orange   {.label-variant(@label-orange-bg);}
	.badge-alizarin   {.label-variant(@label-alizarin-bg);}
	.badge-midnightblue   {.label-variant(@label-midnightblue-bg);}
	.badge-green   {.label-variant(@label-green-bg);}
	.badge-inverse   {.label-variant(@label-inverse-bg);}
	.badge-grape   {.label-variant(@label-grape-bg);}
	.badge-sky   {.label-variant(@label-sky-bg);}
	.badge-deep-purple   {.label-variant(@label-deep-purple-bg);}
	.badge-teal   {.label-variant(@label-teal-bg);}

	.leftbar nav.widget-body>ul.acc-menu {
		.badge.badge-primary  {.label-variant(@label-primary-bg);}
		.badge.badge-indigo  {.label-variant(@label-indigo-bg);}
		.badge.badge-orange  {.label-variant(@label-orange-bg);}
		.badge.badge-success  {.label-variant(@label-success-bg);}
		.badge.badge-info     {.label-variant(@label-info-bg);}
		.badge.badge-warning  {.label-variant(@label-warning-bg);}
		.badge.badge-danger   {.label-variant(@label-danger-bg);}
		.badge.badge-green   {.label-variant(@label-green-bg);}
		.badge.badge-alizarin   {.label-variant(@label-alizarin-bg);}
		.badge.badge-grape   {.label-variant(@label-grape-bg);}
		.badge.badge-midnightblue   {.label-variant(@label-midnightblue-bg);}
	}

//progressbars.less
	.progress-lg {height: 16px !important}
	.progress-sm {height: 4px !important}
	.progress {height: 8px; box-shadow: none;}
	.progress-bar {box-shadow: none;}

	.progress-bar-inverse 		{.progress-bar-variant(@progress-bar-inverse-bg);}
	.progress-bar-primary 		{.progress-bar-variant(@progress-bar-bg);}
	.progress-bar-lime 			{.progress-bar-variant(@progress-bar-lime-bg);}
	.progress-bar-green  		{.progress-bar-variant(@progress-bar-green-bg);}
	.progress-bar-orange  		{.progress-bar-variant(@progress-bar-orange-bg);}
	.progress-bar-purple  		{.progress-bar-variant(@progress-bar-purple-bg);}
	.progress-bar-indigo  		{.progress-bar-variant(@progress-bar-indigo-bg);}
	.progress-bar-midnightblue  {.progress-bar-variant(@progress-bar-midnightblue-bg);}
	.progress-bar-sky  			{.progress-bar-variant(@progress-bar-sky-bg);}
	.progress-bar-alizarin  	{.progress-bar-variant(@progress-bar-alizarin-bg);}



	// Contextual Progress
	div.widget .contextual-progress:last-child {border-bottom: none;}

	.contextual-progress {
		padding: 16px;
		border-bottom: 0;
		.progress-percentage {
			float: right;
			position: relative;
			color: @gray;
		}

		.progress-title {
			float: left; 
			font-weight: 400;
			color: @gray-dark; 

			.checkbox {
				margin-bottom: 0;
				margin-top: 0;
			}

			a {
				font-weight: 400;
				color: @gray-dark;
				&:hover {text-decoration: none;}
			}
		}
		.progress-desc  {font-size: @font-size-small; display: block; margin: 10px 0 0;}


		.progress {
			height: 4px;
			margin: 8px 0 0;
			background: @border-lighter;
		}
	}

	.taskprogresses  .contextual-progress {
		margin: 0;
		padding: 0;
		border: none;
		.progress-title {
			color: @gray;
			font-weight: 400;
		}
		.progress-desc {
			color: @gray-light;
		}
		.progress-percentage {
			color: @gray;
		}
		.progress-striped .progress-bar {
			background-size: 20px 20px !important;
		}
	}

	.label-progressbar{
		height: 16px;
		div.progress-bar{
			line-height: 16px;
		}
	}



//input-groups.less
	.input-group-addon {
		background-color: #f7f7f7 !important; 
		padding: @padding-base-vertical+1  12px @padding-base-vertical+1;
		min-width: 40px;
		color: @gray-dark;
		
	}
	.input-group-btn > .btn {
		+ .dropdown-toggle {
			padding-left: 5px;
			padding-right: 5px;
		}
	}


//Border Fix
	.input-group .form-control {z-index: 0;}

	.input-group-btn {
		&:last-child > .btn, &:last-child > .btn-group {
			margin-left: 0;

			+ .btn, + .btn-group {
				margin-left: -1px;
			}
		}

		&:first-child > .btn, &:first-child > .btn-group {
			margin-right: 0;
		}
	}

	// Reset rounded corners
	.input-group .form-control:first-child,
	.input-group-addon:first-child,
	.input-group-btn:first-child > .btn,
	.input-group-btn:first-child > .btn-group > .btn,
	.input-group-btn:first-child > .dropdown-toggle,
	.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
	.input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
	  .border-right-radius(0) !important;
	}

	.input-group .form-control:last-child,
	.input-group-addon:last-child,
	.input-group-btn:last-child > .btn,
	.input-group-btn:last-child > .btn-group > .btn,
	.input-group-btn:last-child > .dropdown-toggle,
	.input-group-btn:first-child > .btn:not(:first-child),
	.input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
	  .border-left-radius(0) !important;
	}


// Modal
	.modal-header {
		padding: 16px;
		position: relative;
		border-bottom: 1px solid @border-darker;
		.modal-title {
			font-weight: 400;
			font-size: @font-size-h4;
		}
		.close {
			margin-top: -8px;
		    position: absolute;
		    right: 16px;
		    top: 50%;
		    width: 16px;
		    height: 16px;
		    display: block;
		}
	}

	.modal-body {
		padding: 16px;
	}

	.modal-footer {
		padding: 16px;
		border-top: 1px solid @border-darker;
	}

	.modal-content {
		padding: 0;
	    //.box-shadow(0 0 1px rgba(27, 34, 44, 0.12), 0 16px 40px -8px rgba(27, 34, 44, 0.2));
	    border: 0;
	    box-shadow: 0 0 1px rgba(27, 34, 44, 0.12), 0 16px 40px -8px rgba(27, 34, 44, 0.2);
	}


//close
	.close {
		float: right;
		font-size: 24px;
		line-height: 24px;
		text-shadow: none;
	}
	.alert.alert-dismissable{ 
		position: relative;
		.close{
			line-height: 24px;
			@media(max-width: @screen-xs-max){
				position: absolute;
				top: 8px;
				right: 8px;
			}
		}
	}


// Carousel
	.carousel-caption {
		h1, h2, h3, h4, h5, h6,
		.h1, .h2, .h3, .h4, .h5, .h6, p {
			color: #fff;
		}
	}

	.carousel-text {
		.carousel-indicators {bottom: 0;}
		.carousel-control {background-image: none;text-shadow: none;}
		.item {padding: 20px;}


		&.carousel-default {
			.item {background: #fff;}
			.carousel-control {color: #000;}
			.carousel-indicators li {
				border: 1px solid @border-darker;
				&.active {background-color: @border-darker;}
			}
		}

		&.carousel-inverse {
			.item {background: @brand-inverse;}
			.carousel-control {color: #fff;}
			h1, h2, h3, h4, h5, h6,
			.h1, .h2, .h3, .h4, .h5, .h6, p {
				color: #fff;
			}
		}
	}