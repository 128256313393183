// Get Colors
// -------------------------
@import "colors/default.less";
@import "colors/graphite.less";
@import "colors/holly.less";
@import "colors/havana.less";
@import "colors/haiti.less";
@import "colors/bunting.less";



// Mixin
//--------------------------------
.horizontal-variant(@variant) {

	@horizontal-background: "horizontal-@{variant}-background";
	@horizontal-hover-bg: "horizontal-@{variant}-hover-bg";
	@horizontal-hover-color: "horizontal-@{variant}-hover-color";

	@horizontal-submenu-background: "horizontal-@{variant}-submenu-background";
	@horizontal-submenu-item-color: "horizontal-@{variant}-submenu-item-color";

	@horizontal-submenu-item-hover-bg: "horizontal-@{variant}-submenu-item-hover-background";
	@horizontal-submenu-item-hover-color: "horizontal-@{variant}-submenu-item-hover-color";

	@horizontal-menu-color: "horizontal-@{variant}-menu-color";
	@horizontal-icon-color: "horizontal-@{variant}-icon-color";

	@horizontal-active-bg: "horizontal-@{variant}-active-bg";
	@horizontal-active-color: "horizontal-@{variant}-active-color";


	background: @@horizontal-background;
	
	.navbar-nav {
		margin-top: 0;
		margin-bottom: 0;
		background: @@horizontal-background;

		> .open > a, > .open > a i {
			background: @@horizontal-hover-bg;
			color: @@horizontal-hover-color;
		}
		ul {
			background-color: @@horizontal-submenu-background;
			border: none;
			border-radius: 0px;
			li a {
				color: @@horizontal-submenu-item-color;
				&:hover {
					background: @@horizontal-submenu-item-hover-bg;
					color: @@horizontal-submenu-item-hover-color;					
				}
			}
		}

		li {
			a {
				color: 				@@horizontal-menu-color;
				i {color: 			@@horizontal-icon-color;}
			}
			&.active a, &.active a i, &.active a:hover, &.active a:hover i {
				background-color: 	@@horizontal-active-bg;
				color: 				@@horizontal-active-color;
			}
			a:hover, a:hover i {
				background: 		@@horizontal-hover-bg;
				color: 				@@horizontal-hover-color;
			}
			a:focus, a:focus i {
				background: 		@@horizontal-hover-bg;
				color: 				@@horizontal-hover-color;
			}
			
			i {margin-right: 2px;}
		}

	}

	.navbar-toggle {
		&, &:hover, &:focus {
			background: transparent;
			color: @@horizontal-menu-color;
			border:none;
		}
	}
}

.leftbar-variant(@variant) {
	@leftbar-bg-color: "leftbar-@{variant}-bg-color";
	@leftbar-color: "leftbar-@{variant}-color";

	@leftbar-link-color: "leftbar-@{variant}-link-color";
	@leftbar-link-hover-color: "leftbar-@{variant}-link-hover-color";

	@leftbar-menu-color: "leftbar-@{variant}-menu-color";
	@leftbar-menu-icon-color: "leftbar-@{variant}-menu-icon-color";
	@leftbar-menu-hover-color: "leftbar-@{variant}-menu-hover-color";
	@leftbar-menu-hover-bg-color: "leftbar-@{variant}-menu-hover-bg-color";
	@leftbar-menu-active-color: "leftbar-@{variant}-menu-active-color";
	@leftbar-menu-active-bg-color: "leftbar-@{variant}-menu-active-bg-color";
	@leftbar-menu-border-color: "leftbar-@{variant}-menu-border-color";

	@leftbar-submenu-bg-color: "leftbar-@{variant}-submenu-bg-color";
	@leftbar-submenu-color: "leftbar-@{variant}-submenu-color";
	@leftbar-submenu-hover-color: "leftbar-@{variant}-submenu-hover-color";
	@leftbar-submenu-hover-bg-color: "leftbar-@{variant}-submenu-hover-bg-color";

	@leftbar-muted-color: "leftbar-@{variant}-muted-color";

	@leftbar-custom-color1: "leftbar-@{variant}-custom-color1";
	@leftbar-custom-color2: "leftbar-@{variant}-custom-color2";
	@leftbar-custom-color3: "leftbar-@{variant}-custom-color3";
	@leftbar-custom-color4: "leftbar-@{variant}-custom-color4";

	background-color: @@leftbar-bg-color;

	#leftbar-search {
		input {
			color: @@leftbar-menu-color;
			background-color: @@leftbar-menu-hover-bg-color;
			.placeholder(@@leftbar-menu-icon-color);

			&:active, &:focus {
				color: @@leftbar-menu-active-color;
				background-color: @@leftbar-menu-hover-bg-color;
			}
		}	
		button {
			color: @@leftbar-menu-icon-color;
			&:hover {color: @@leftbar-menu-hover-color;}
		}
	}
	.widget {
		.widget-heading, .nav-separator {
			color: @@leftbar-muted-color;
			border-color: @@leftbar-custom-color1;
		}
		
		.widget-body {

			.media-list .media {
				.media-left {
					.icon {
					}
				}
				
				a, .media-content {
					background-color: @@leftbar-bg-color;
					&:hover {
						background-color: @@leftbar-bg-color;
					}
				}

				.media-body {
					color: @@leftbar-color;
					.text {
						color: @@leftbar-color;
					}
					.name {
						color: @@leftbar-menu-hover-color; 
					}
					.link {
						color: @brand-primary; 
					}
					.time {
						color: @@leftbar-muted-color;
					}
				}	
			}

			.userinfo {
			    .avatar {
			  		//img + .status {
					// 	border-color: @@leftbar-bg-color;
					// }
				}
				.info {
					.name {
					    color: @@leftbar-menu-hover-color;
					}
					.email {
					    color: @@leftbar-menu-icon-color;
					}
				}
			}

			.sparkline {
				.info {
					.title {color: @@leftbar-menu-color;}
					.text {color: @@leftbar-menu-hover-color;}
				}
				.bar, .pie, .line {
				}
			}

			#tree-default {
				.jstree-icon {
					color: @@leftbar-menu-icon-color;
				}
				.jstree-anchor {
					color: @@leftbar-menu-color;
					.jstree-icon {
						color: @@leftbar-menu-icon-color;
					}
					&:hover {
						background-color: @@leftbar-menu-hover-bg-color !important;
						color: @@leftbar-menu-hover-color !important;
					}
					&.jstree-clicked {
						background-color: @@leftbar-menu-active-bg-color !important;
						color: @@leftbar-menu-active-color !important;
					}
				}
			}
		}
	}

	nav.widget-body > ul.acc-menu {

		>li.active>ul {border-color: @@leftbar-menu-border-color;}

		ul, ul li a {background: @@leftbar-submenu-bg-color; color: @@leftbar-submenu-color;}

		//Main Menu Styles - Level 1 
		li a {color: @@leftbar-menu-color; background: @@leftbar-bg-color;}
		>li:hover>a {background-color: @@leftbar-menu-hover-bg-color; color: @@leftbar-menu-hover-color; i{color: @@leftbar-menu-hover-color;}}
		>li:hover.open>a, >li.open>a {background-color: @@leftbar-submenu-bg-color;}
		>li.active>a, >li:hover.open.active>a {border-color: @@leftbar-menu-border-color; color: @@leftbar-menu-active-color; background-color: @@leftbar-menu-active-bg-color}

		// Level 1 Icon styles
		> li > a > i {color: @@leftbar-menu-icon-color; }
		> li > a:hover > i {color: @@leftbar-menu-hover-color;}
		> li.active > a > i, > li.active > a:hover > i {color: @@leftbar-menu-active-color;}


		// Submenu Styles - Level 2
		ul li a {border-top: 1px solid transparent} //
		ul li a:hover {background-color: @@leftbar-submenu-hover-bg-color; color: @@leftbar-menu-hover-color;}
		ul li.active:not(.open)>a {color: @@leftbar-submenu-hover-color;}
		
		li.disabled-link a {
	   		color: @@leftbar-muted-color;
		}
	}

	.leftbar nav.widget-body > ul.acc-menu {
		//Main Menu Styles - Level 1 
		> li > a {span.icon{color: @@leftbar-menu-icon-color}; span.text{color: @@leftbar-menu-color;}}
		> li:hover > a {span.icon{color: @@leftbar-menu-hover-color}; span.text{color: @@leftbar-menu-hover-color;}}
		> li.open > a, > li.active > a {span.icon{color: @@leftbar-menu-active-color}; span.text{color: @@leftbar-menu-active-color;}}
	}
}

.topnav-variant(@variant) {

	@topnav-bg-color: "topnav-@{variant}-bg-color";
	@topnav-color: "topnav-@{variant}-color";

	@topnav-link-color: "topnav-@{variant}-link-color";
	@topnav-link-hover-color: "topnav-@{variant}-link-hover-color";
	@topnav-link-bg-color: "topnav-@{variant}-link-bg-color";
	@topnav-link-hover-bg-color: "topnav-@{variant}-link-hover-bg-color";
	@topnav-link-active-color: "topnav-@{variant}-link-active-color";
	@topnav-link-active-bg-color: "topnav-@{variant}-link-active-bg-color";
	@topnav-link-disabled-color: "topnav-@{variant}-link-disabled-color";


	background-color: @@topnav-bg-color;
	border-color: @@topnav-bg-color;

	.navbar-brand {
		color: @@topnav-link-color;
		&:hover,
		&:focus {
			color: @@topnav-link-color;
			background-color: @@topnav-link-bg-color;
		}
	}

	#trigger-leftbar,
	#trigger-infobar,
	.toolbar-trigger {
		a, button {
			// icon and link
			background-color: @@topnav-link-bg-color;
			color: @@topnav-link-color;
			&:hover {
				color: @@topnav-link-hover-color;
				background-color: @@topnav-link-hover-bg-color;
			}
		}
	}

	.navbar-nav > li > a {
		// icon and link
		background-color: @@topnav-link-bg-color;
		color: @@topnav-link-color;
		&:hover, &:focus, &:active {
			color: @@topnav-link-hover-color;
			background-color: @@topnav-link-hover-bg-color;
		}
	}

	#toolbar-search {
		input {
			color: @@topnav-link-color;
			background-color: @@topnav-link-bg-color;
			.placeholder(@@topnav-link-color);

			&:active, &:focus {
				color: @@topnav-link-active-color;
				background-color: @@topnav-link-active-bg-color;
			}
		}	
		button {
			color: @@topnav-link-color;
			&:hover {color: @@topnav-link-color;}
		}
	}
}



// leftbar Nav Variants

.leftbar-default {
	.leftbar-variant(~"default");
}

.leftbar-graphite {
	.leftbar-variant(~"graphite");
}

.leftbar-holly {
	.leftbar-variant(~"holly");
}
.leftbar-havana {
	.leftbar-variant(~"havana");
}
.leftbar-haiti {
	.leftbar-variant(~"haiti");
}
.leftbar-bunting {
	.leftbar-variant(~"bunting");
}
// Horizontal Nav variants

#headernav {
	&.navbar-default {
		.horizontal-variant(~"default");
	}
}

#headernav {
	&.navbar-graphite {
		.horizontal-variant(~"graphite");
	}
}

#headernav {
	&.navbar-holly {
		.horizontal-variant(~"holly");
	}
}

#headernav {
	&.navbar-havana {
		.horizontal-variant(~"havana");
	}
}

#headernav {
	&.navbar-haiti {
		.horizontal-variant(~"haiti");
	}
}

#headernav {
	&.navbar-bunting {
		.horizontal-variant(~"bunting");
	}
}

// Topnav
#topnav {

	&.navbar-default {
		.topnav-variant(~"default");
	}

	&.navbar-graphite {
		.topnav-variant(~"graphite");
	}

	&.navbar-holly {
		.topnav-variant(~"holly");
	}

	&.navbar-havana {
		.topnav-variant(~"havana");
	}

	&.navbar-haiti {
		.topnav-variant(~"haiti");
	}

	&.navbar-bunting {
		.topnav-variant(~"bunting");
	}
}