//navs.less
	.nav-tabs .dropdown-menu {margin-top:0px;}
	.nav-tabs > li > a {margin-right: 0px;}
	.nav-tabs > li > a:hover {border-color: @gray-lighter;}

	.nav .open > a {
	    &, &:hover, &:focus {border-color: @gray-lighter;}
	}

//button-groups.less

	.btn-toolbar {margin-left: 0;}
	.btn.dropdown-toggle {min-width: 32px;}

	.btn-group, .btn-group-vertical {
		padding: 0;
		> .btn.fc-state-active {
			z-index: 2;
		}
		.btn-group + .btn-group {
	    	margin-left: 10px;
		}
	}
	 .btn-toolbar {
	 	.btn-group {float: none;}
	 	> .btn + .btn,
	 	> .btn-group + .btn,
	 	> .btn + .btn-group,
	 	> .btn-group + .btn-group {margin-left: 0px;}
	 }
	.btn-group {
		> .btn + .dropdown-toggle {
		  padding-left: 5px;
		  padding-right: 5px;
		}
		> .btn-lg + .dropdown-toggle {
		  padding-left: 15px;
		  padding-right: 15px;
		}
		&.open .dropdown-toggle {
			.box-shadow(inset 0 2px 2px rgba(0,0,0,.1));
		}
	}

//breadcrumbs.less
	.breadcrumb {
		border: 1px solid @border-lighter !important;
		margin-bottom: 0;
		> li {
			color: @breadcrumb-color;
			font-size: @font-size-mini !important;
		}
	}

//pagination.less
	.pagination {
		margin: 10px 0;
		> li {
			> a, span {
				&:hover, &:focus {
					background: @border-lighter;
				}
			}
		}
	}

//pager.less
	.pager li {
		> a{
			padding: 6px 15px;
			color: @gray-dark;
			border-color: @pagination-hover-border;
		}
	}

//navbar.less
	.navbar {
		border: none !important;
	}

	#headernav {
		.navbar-collapse {
			padding-left: 20px;
			padding-right: 20px;
		}
		.navbar-nav {
			@media (max-width: @screen-xs-max) {
				margin: 0 -20px;
			}
		}
	}

// list group 

.list-group.nav-tabs {
	border-bottom: 0;
}