//dropdowns.less

.btn .caret {
  display: inline-block;
  line-height: inherit;
  height: auto;
  width: auto;
  font-style: 1.1em;
}

.caret {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    display: inline;
    margin-left: 4px;
    border:0;
    font-size: 1.1em;

    vertical-align: initial;  
    line-height: 1;
	&:before {
    	content: "\f107"; 
    	font-family: FontAwesome;
    	font-style: normal;
    	font-weight: normal;
    	text-decoration: inherit;
	}
}

.dropup .caret {
	border: 0;
	&:before {
    	content: "\f106"; 
    }
}

.yamm .dropdown-menu {
	left: 0!important;
			right: 0;
}

@media(max-width: 980px){
	.yamm #megamenu>li {
		width: 500px !important;
		white-space:nowrap;
		overflow-x: scroll;
		overflow-y: hidden;
		.yamm-content{
		    white-space:nowrap;
		    width: 900px !important;	
		}
	}
}

@media(max-width: 1024px){
	.yamm #megamenu>li {
		width: 500px !important;
		white-space:nowrap;
		overflow-x: scroll;
		overflow-y: hidden;
		.yamm-content{
		    white-space:nowrap;
		    width: auto!important;	
		    .row{
		    	margin: 0;
		    	.col-sm-height.yamm-col{
		    		padding: 0 16px 8px;
		    	}
		    }
		}
	}
}
@media(max-width: 767px){
	.yamm #megamenu>li {
		overflow-x: scroll;
		overflow-y: hidden;
		.yamm-content{
		    width: 100%!important;	
		}
	}
}

// img
.img-rounded {border-radius: @border-radius-base;}



// Dropdown Menu

.btn, .btn-group{
	.dropdown-menu{
		transform: none!important;
		opacity: unset!important;
	}
	&.btn-block{
		.dropdown-menu{
			width: 100%!important;
		}
	}
}

.category-heading {
	line-height: 32px;
	padding: 0 16px;
	margin-top: 0;
	margin-bottom: 0;
	display: block;
	font-weight: 700;
	font-size: @font-size-small;
	text-transform: uppercase;
	color: @gray-light;
}

.dropdown.open .dropdown-menu:not(.datepicker):not(.daterangepicker):not(.datetimepicker) {
	opacity: 1 !important; 
	transform: scale(1,1) !important;
	display: block !important;
}

.dropdown-menu:not(.datepicker):not(.daterangepicker):not(.datetimepicker) {
	width: 200px;
	border: 0;
	padding: 8px 0;
	border-radius: @border-radius-base;
	transform: scale(1,0);
	opacity: 0;
	transform-origin: center top 0;
	transition: all .125s cubic-bezier(0.4, 0, 0.2, 1);
	box-shadow: 0 0 1px rgba(27, 34, 44, 0.32), 0 16px 40px -8px rgba(27, 34, 44, 0.20);
	@media(max-width: @screen-xs-max){
	    width: 100%;
	}

	li > a {
		line-height: 16px;
		padding: 8px;
		margin: 0 8px;
		font-size: @font-size-base;
		border-radius: @border-radius-base;
		span {
			padding-left: 32px;
			white-space: normal;
			display: block;
		}
		i {
			position: absolute;
			height: 16px;
			width: 16px;
			line-height: 16px;
			display: inline-block;
			font-size: @icon-font-size-base;
			text-align: center;
		}
		&:hover {i {color: @gray-dark;}}
	}
	&.without-icon {
		.category-heading {padding: 0 16px!important;}
		span {padding-left: 0 !important;}
		i {display: none;}
	}

	.divider {
		margin: 8px 0;
	}
}


.dropdown-lg .dropdown-menu {
	width: 320px;
	@media(max-width: @screen-xs-max){
		min-width: auto;
	    width: 100%;
	}
	
} 

.right-dropdown-menu{
	right: 0;
	left: auto;
}


//Media

.media {
	margin-top: 0;
	.media-left{
		padding-right: 16px;
		padding-left: 0px;
		position: relative;
		img.avatar + .status {
			height: 10px;
			width: 10px;
			border-radius: 50%;
			border-style: solid;
			border-width: 2px;
			border-color: #fff;
			position: absolute;
			top: 24px;
			left: 24px;
			padding: 0 !important;
			&.online {background-color: @brand-success;}
			&.busy {background-color: @brand-danger;}
			&.away {background-color: @brand-amber;}
			&.offline {background-color: @gray-300;}
		}

		.avatar {
			border-radius: 50%;
			height: 32px;
			width: 32px;
			display: block!important;
			position: relative;
			&:after {
				content: '';
				height: 6px;
				width: 6px;
				border-radius: 50%;
				border: 2px solid #fff;
				position: absolute;
				bottom: 0;
				right: 0;
			}
		}
		.input {
			height: 32px;
			width: 32px;
			display: block;
			position: relative;
			font-size: 0;
			border-radius: 50%;
			label {
				height: 32px !important;
				width: 32px !important;
				padding: 8px !important;
				&:hover {cursor: pointer;}
			}
		}
		.switch {
			display: block;
			font-size: 0;
			padding: 8px 0;
			position: relative;
			height: 32px;
			width: 32px;
			text-align: center;
			label {margin: 0;}
			input {display: none;}
		}
		.icon {
			height: 32px;
			width: 32px;
			display: block;
			border-radius: 50%;
			position: relative;
			text-align: center;
			text-transform: uppercase;
			color: rgba(255, 255, 255, 0.8);
			font-weight: 700;
			line-height: 32px;
			&.text {background: transparent; color: @gray-dark; font-weight: 700; font-size: @font-size-base;}
			&.success {background: @brand-success!important;}
			&.primary {background: @brand-primary!important;}
			&.warning {background: @brand-warning!important;}
			&.danger {background: @brand-danger!important;}
			&.info {background: @brand-info!important;}
			&.green {background: @brand-green!important;}
			&.orange {background: @brand-orange!important;}
			&.indigo {background: @brand-indigo!important;}
			&.inverse {background: @brand-inverse!important;}
			&.purple {background: @brand-purple!important;}
			&.blue {background: @brand-blue!important;}
			&.teal {background: @brand-teal!important;}
			&.lime {background: @brand-lime!important;}
			i {
				display: block;
				text-align: center;
				line-height: 16px;
				width: 32px !important;
				height: 32px !important;
				color: rgba(255, 255, 255, 0.8)!important;
				padding: 8px;
				top: 0;
				left: 0;
				font-size: @icon-font-size-base;
			}
		}
	}

	.media-right{
		padding-left: 16px;
		padding-right: 0;
		position: relative;
		img.avatar + .status {
			height: 10px;
			width: 10px;
			border-radius: 50%;
			border-style: solid;
			border-width: 2px;
			border-color: #fff;
			position: absolute;
			top: 24px;
			left: 24px;
			padding: 0 !important;
			&.online {background-color: @brand-success;}
			&.busy {background-color: @brand-danger;}
			&.away {background-color: @brand-amber;}
			&.offline {background-color: @gray-300;}
		}

		.avatar {
			border-radius: 50%;
			height: 32px;
			width: 32px;
			display: block!important;
			position: relative;
			&:after {
				content: '';
				height: 6px;
				width: 6px;
				border-radius: 50%;
				border: 2px solid #fff;
				position: absolute;
				bottom: 0;
				right: 0;
			}
		}
		.input {
			height: 32px;
			width: 32px;
			display: block;
			position: relative;
			font-size: 0;
			border-radius: 50%;
			label {
				height: 32px !important;
				width: 32px !important;
				padding: 8px !important;
				&:hover {cursor: pointer;}
			}
		}
		.switch {
			display: block;
			font-size: 0;
			padding: 8px 0;
			position: relative;
			height: 32px;
			width: 32px;
			text-align: center;
			label {margin: 0;}
			input {display: none;}
		}
		.icon {
			height: 32px;
			width: 32px;
			display: block;
			border-radius: 50%;
			position: relative;
			text-align: center;
			text-transform: uppercase;
			color: rgba(255, 255, 255, 0.8);
			font-weight: 700;
			line-height: 32px;
			&.text {background: transparent; color: @gray-dark; font-weight: 700; font-size: @font-size-base;}
			&.success {background: @brand-success!important;}
			&.primary {background: @brand-primary!important;}
			&.warning {background: @brand-warning!important;}
			&.danger {background: @brand-danger!important;}
			&.info {background: @brand-info!important;}
			&.green {background: @brand-green!important;}
			&.orange {background: @brand-orange!important;}
			&.indigo {background: @brand-indigo!important;}
			&.inverse {background: @brand-inverse!important;}
			&.purple {background: @brand-purple!important;}
			&.blue {background: @brand-blue!important;}
			&.teal {background: @brand-teal!important;}
			&.lime {background: @brand-lime!important;}
			i {
				display: block;
				text-align: center;
				line-height: 16px;
				width: 32px !important;
				height: 32px !important;
				color: rgba(255, 255, 255, 0.8)!important;
				padding: 8px;
				top: 0;
				left: 0;
				font-size: @icon-font-size-base;
			}
		}
	}
	
	a, .media-content {
		display: block;
		padding: 12px 16px;
		position: relative;
		border: 0;
		&:hover {
			background-color: @gray-50;
			.action {
				display: block;
				right: 8px;
				top: 16px;
			}
		}
	}
	
	.action {
		display: none;
		position: absolute;
		a {
			padding: 8px !important;
			border: 0 !important;
			margin: 0 !important;
			float: left;
			i {
				text-decoration: none;
				font-size: @icon-font-size-base;
				text-align: center;
				color: @gray-lighter;
				position: relative !important;
				display: block !important;
				line-height: 16px;
				width: 16px;
				height: 16px;
				background: none !important;				
			}
			&:hover {
				background: none !important;
				i {color: @gray;}
			}
		}
	}

	.media-body {
		padding: 0;
		font-size: @font-size-base;
		color: @gray;
		line-height: @line-height-base;
		width: 100%;

		.text {
			color: @gray-dark;
			display: block !important;
			&.text-muted {color: @gray-light;}
		}
		.name {
			color: @gray-darker; 
			display: inline !important;
			padding: 0 !important;
			font-weight: 600;
		}
		.contact {
			color: @gray-darker; 
			display: block !important;
			padding: 0 !important;
			font-weight: 600;
			line-height: 16px;
		}
		.title {
			display: block;
			line-height: 16px;
			color: @gray-dark;
		}
		.message {
			color: @gray; 
			display: inline !important;
			padding: 0 0 0 16px !important;
			position: relative;
			&:before {
				content: '—';
				color: @gray-lighter;
				font-size: @font-size-base;
				line-height: 16px;
				width: 16px;
				text-align: center;
				position: absolute;
				left: 0;
				top: 0;
			}
		}
		.link {
			color: @brand-primary; 
			display: inline !important;
			padding: 0 !important;
		}
		.info, .time {
			display: block;
			font-size: @font-size-small;
			line-height: 16px;
			color: @gray-light;
			padding: 4px 0 0 !important;
		}
		.tags {
			display: block;
			line-height: 16px;
			padding: 4px 0 0;
		}
		.progress{
			height: 4px;
			margin: 8px 0 0 0;
			.progress-bar{
				margin: 0;
			}
		}
	}	
}




//wells.less

.well {
	padding: 24px;
	border: none;
	background-color: @border-darker;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
    &.well-transparent {
		background: none;
	}
	&.well-code {
		font-size: @font-size-small;
		font-family: @font-family-monospace;
	}
	&.well-sm {padding: 8px;}
	&.well-lg {padding: 40px;}
}

//list-group.less

.list-group { 
	> .tabdrop + .list-group-item {
		.border-top-radius(@list-group-border-radius);
	}
}

.row > .full-width {
    padding: 0;
}


