.leftbar, .commonWrapper {
	.widget {
		.widget-heading {
			&:extend(.category-heading all);
			margin-bottom: 0;
			padding: 0 16px;
			margin-top: 0;
		}

		.widget-body {
			padding: 0 16px;
			display: block;

			// search
			#leftbar-search {
				position: relative;
				input {
					height: 32px;
				    line-height: 20px;
				    padding: 6px 8px 6px 8px;
				    margin: 0;
				    border-radius: 2px;
				    border: 0;
				    box-shadow: none !important;
				}
				button {
					position: absolute;
				    background: transparent;
				    border: 0;
				    top: 6px;
				    right: 8px;
				    height: 20px;
				    width: 20px;
				    line-height: 16px;
				    display: inline-block;
				    padding: 0px;
				    font-size: @icon-font-size-base;
				    text-align: center;
				    float: left;
				}
			}

			//userinfo
			.userinfo {
			    margin-right: auto;
			    padding: 16px 0;
			    position: relative;
			    .avatar {
				    height: 40px;
				    width: 40px;
				    position: absolute;
				    img + .status {
						height: 10px;
						width: 10px;
						border-radius: 50%;
						border-style: solid;
						border-width: 2px;
						border-color: #fff;
						position: absolute;
						top: 30px;
						left: 30px;
						padding: 0 !important;
						&.online {background-color: @brand-success;}
						&.busy {background-color: @brand-danger;}
						&.away {background-color: @brand-amber;}
						&.offline {background-color: @gray-300;}
					}
				}
				.info {
					float: left;
					margin-left: 16px;
					.venue {
					    display: block;
					    font-size: @font-size-large;
					    text-align: center;
					    margin-top: 0;
					    line-height: 26px;
					}
					.name {
					    display: block;
					    font-size: @font-size-base;
					    text-align: center;
					    margin-top: 0;
					    line-height: 22px;
					}
					.email {
					    font-size: @font-size-small;
					    display: block;
					    text-align: center;
					    line-height: 18px;
					}
				}
			}

			//sparklines
			.sparkline {
				&:extend(.clearfix all);
				padding: 16px 0;
				.info {
					float: left;
					display: inline-block;
					.title {
						font-size: @font-size-small;
						line-height: 16px;
						display: block;
					}
					.text {
						font-size: @font-size-h4;
						line-height: 32px;
						display: block;
					}
				}
				.bar, .pie, .line, .tristate {
					height: 32px;
					padding: 4px 0;
					margin-top: 16px;
					float: right;
					display: inline-block;
				}
			}

			//medialists
			.media-list {
				.media {
					a {padding: 12px 0;}
				}
			}

			//treeview
			#tree-default {
				> .jstree-container-ul > .jstree-node {margin-left: 0 !important;}
				.jstree-closed > .jstree-ocl {
						background-position: -104px 0px !important;
				}
				.jstree-open > .jstree-ocl {
					background-position: -136px 0px !important; 
				}
				.jstree-icon {
					width: 16px;
			    line-height: 32px;
			    height: 32px;
			    font-size: @icon-font-size-base; 
				}
				.jstree-anchor {
					background: transparent none repeat scroll 0 0 !important;
					height: 32px;
					line-height: 32px;
					padding: 0 8px;
					.jstree-icon {
						width: 16px;
						line-height: 32px;
						height: 32px;
						margin: 0 8px 0 0;
					}
					&.jstree-clicked {
						border: none;
						box-shadow: none !important;
						border-radius: @border-radius-base !important;
						font-weight: 600;
					}
					&:hover {border-radius: @border-radius-base !important;}
				}
				.jstree-node {
					min-height: 32px;
				    line-height: 32px;
				    margin-left: 24px;
				    min-width: 24px; 
				}
			}
		}	
	}
	.widget + .widget {margin-top: 16px;}
}

@media (min-width: @screen-sm) { //Only show Avatar when collapsed
	body.leftbar-collapsed .leftbar .welcome-box {
		padding: 24px 4px;
		.tabular-cell:not(.welcome-avatar) {
			display: none;
		}
	}
}

.commonWrapper{
	#leftbar-search{
		border: 1px solid @gray-300;
		border-radius: 2px;
	}
}