// Headerbar 
#headerbar {
	top: -1000px;
	left: 0;
	position: fixed;
	padding: 0;
	z-index: 9999;
	width: 100%;
	-webkit-transition: top .25s ease;
	-o-transition: top .25s ease;
	transition: top .25s ease;
	.container-fluid{
		padding: 0;
		background-color: rgba(27, 34, 44, 0.96);
		.yamm-col{padding: 0 16px;}
		.yamm-content{
			background: transparent;
			padding: 16px;
			box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.32);
			.yamm-col-bordered{
				h3{
					margin: 0;
					font-size: 24px;
					font-weight: 300!important;
					color: rgba(255, 255, 255, 0.72);
					text-transform: uppercase;
					padding: 8px 0;
					margin-bottom: 16px;
				}
				.yamm-col{
					.yamm-category{}
					ul li
					{ 
						display: block;
					    font-weight: 400;
					    margin: 0 -16px; 
						> a 
						{	
							padding: 8px;
							margin: 0 8px;
							display: block;
							line-height: 16px;
							border-radius: @border-radius-base;
							&:hover {
								background: rgba(255, 255, 255, 0.04);
							}
							i {
								display: inline-block;
							    font-size: @icon-font-size-base;
							    height: 16px;
							    line-height: 16px;
							    margin-left: 0;
							    margin-right: 16px;
							    text-align: center;
							    width: 16px;
							    color: rgba(255, 255, 255, 0.56);
							}
							span{
								color: rgba(255, 255, 255, 0.88);
								font-weight: 400;
								font-size: @font-size-base;
								line-height: 20px;
							}
							span.muted{
								display: block;
								color: rgba(255, 255, 255, 0.32);
								font-weight: 400;
							}
							span.name{
								color: rgba(255, 255, 255, 0.88);
								font-weight: 600;
								display: block;
							}
						}
					}
				}
			}
		}
	}
} 

#topnav {
	margin-bottom: 0px;
	border: none;
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);

	.img-dark  {display: block !important;}
	.img-white {display: none !important;}

	// Logo Positionings
	.navbar-header {
		display: inline-block;
		float: left;
		.navbar-brand {
			width: 240px;
			padding: 0 16px !important;
			@media(max-width: 640px){
				width: auto;
			}
			img {
				height: 42px;
				margin: 8px 0;
			}
			.img-white{display: block;}
			.img-dark{display: none;}
		}
	}

	// Navigation-bar
	.nav.navbar-nav {
		&.navbar-right.yamm {
			.dropdown-menu {
				right: 8px;
				background: #fff!important;
				margin-right: 0;
				transform-origin: right top 0;
			    display: block;
			    left: auto!important;
			    @media(max-width: @screen-xs-max){
			    	right: 0;
			    }
			    .scroll-pane > .scroll-content{
				    overflow-y: auto!important;
				    position: relative!important;
				}
				&:before {
					left:auto!important;
					right: 12px;
				}
			}
			.username .text {font-weight: 600}
			.username .dropdown-menu li a {padding: 8px;}
			.username.dropdown {
				.userinfo {
					text-align: center;
					padding: 16px;
					.avatar {
						height: 80px;
						width: 80px;
						margin: 0 auto 8px;
						display: block;
					}
					.name {
						font-size: @font-size-large;
						color: @gray-dark;
						font-weight: 600;
						line-height: 24px;
						display: block;
					}
					.email {
						font-size: @font-size-small;
						color: @gray;
						line-height: 16px;
						display: block;
					}
				}
			}
		}
		
		> li {
			position: relative;
			.dropdown-menu{
				border-radius: @border-radius-base;
				display: block;
				@media(max-width: @screen-xs-max) {
					width: 100%;
					margin: 8px 0;
				}
				.dropdown-header, .dropdown-footer {
					font-size: @font-size-small;
					color: @gray;
					font-weight: 700;
					line-height: 24px;
					text-transform: uppercase;
					padding: 8px 16px;
					span {display: inline-block;}
					a {
						text-decoration: none;
						&:hover {text-decoration: none;}
					}
				}
				.dropdown-header {border-bottom: 1px solid @border-darker;}
				.dropdown-footer {border-top: 1px solid @border-darker; font-weight: 600;}
			}
			> a {
				padding: 8px;
				border-radius: @border-radius-base;
				margin: 8px 8px 8px 0;
				height: 40px;
				span.icon {
					height: 24px;
					width: 24px;
					line-height: 16px;
					display: inline-block;
					padding: 4px;
					font-size: @icon-font-size-base;
					text-align: center;
					float: left;
				}
				span.text {
					display: inline-block;
					font-size: @font-size-base;
					line-height: 24px;
					padding: 0 4px;
					float: left;
					font-weight: 400;
				}
				span.avatar {
					width: 32px;
					height: 32px;
					border-radius: @border-radius-base;
					display: inline-block;
					float: right;
					margin: -4px -4px -4px 8px;
				}
				span.badge, span.label{
					position: absolute;
					right: 4px;
					top: 4px;
				}
				@media(max-width: @screen-xs-max) {span.text{float: none;}}
			}
			&:first-child{
				> a{
					@media (max-width: @screen-xs-max){
						margin-top: 0;
					}
				}
			}
		}
	}


	// Toolbar Search
		.no-border{ border-right-style: none !important;}
		#toolbar-search {
			float: left;
			position: relative;
			margin-right: 8px;
			input {
				height: 40px;
			    line-height: 24px;
			    padding: 8px 8px 8px 36px;
			    margin: 8px 0px 8px 0px;
			    border-radius: 2px;
			    border: 0;
			    box-shadow: none !important;
				&:focus {box-shadow: none !important;}
				@media (max-width: 800px) {
					padding: 8px 8px 8px 36px;
				}
			}
			button {
				position: absolute;
			    background: transparent;
			    border: 0;
			    top: 16px;
			    left: 8px;
			    height: 24px;
			    width: 24px;
			    line-height: 16px;
			    display: inline-block;
			    padding: 4px;
			    font-size: @icon-font-size-base;
			    text-align: center;
			    float: left;
			}
		}

		
	// Trigger Styles
		#trigger-leftbar a , #trigger-search{float: left;}
		#trigger-infobar a {
			float: right; margin: 8px 8px 8px 16px;
		}
		.navbar-toggle {margin: 0; border: 0;}

		.toolbar-trigger {
			a, button {
				padding: 8px;
				border-radius: @border-radius-base;
				margin: 8px;
				font-size: 0;
				height: 40px;
				span.icon {
					height: 24px;
					width: 24px;
					line-height: 16px;
					display: inline-block;
					padding: 4px;
					font-size: @icon-font-size-base;
					text-align: center;
					float: left;
				}
			}
		}
		.toolbar-trigger a, .toolbar-trigger button {
			border-radius: 2px;
			font-size: 0;
			height: 40px;
			margin: 8px;
			padding: 8px;
		}

	// Notifications
	.notifications, .messages {
		width: 320px;
		padding: 0;
		li > a {
			padding: 12px 16px;
			margin: 0;
			border-radius: 0;
		}
		.scroll-pane {
			height: 320px;
			overflow-y: scroll;
			overflow-x: hidden; 
			padding: 0;
			.new a {
				border-left: 0px solid @teal-300;
				background-color: rgba(red(@teal-500), green(@teal-500), blue(@teal-500), 0.02);
				&:hover {background-color: rgba(red(@teal-500), green(@teal-500), blue(@teal-500), 0.08);}
			}
		}	
		.mark-read {
			font-size: @icon-font-size-base;
			line-height: 16px;
			width: 16px;
			text-align: center;
			padding: 4px 0;
			color: @gray-light;
			cursor: pointer;
			&:hover {color: @gray-darker;}
		}
	}

	.dropdown-menu.tabbed {
		width: 320px;
		> ul {border-bottom: 2px solid @border-darker;}
		padding: 0;
		ul {
			margin: 0;
			padding: 0;
			
			li {
				padding: 0;
				list-style-type: none;
				a {
					font-weight: 600;
					margin: 0 0 -2px;
					padding: 8px 8px 9px;
					border-radius: 0;
					text-align: center;
					display: block;
					//color: @gray-light;
					border-bottom: 2px solid transparent;
					span.icon {
						display: inline-block;
					    font-size: @icon-font-size-base;
					    height: 22px;
					    line-height: 24px;
					    padding: 0 !important;
					    text-align: center;
					    color: @gray-light;
						i {
						    position: relative;
						}
					}
				}
				&.active a {
					&:after {
						background: @gray-darker;
					}
					i {color: @gray-darker;}
				}
			}
		}
	}
}

.navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
    max-height: auto !important;
}

@media(max-width: 1090px) and  (min-width: 993px){
	#topnav{
		span.text{display: none!important;}
	}
}

@media(max-width: @grid-float-breakpoint-max){
	#topnav {
		.navbar-nav.nav {
			&.navbar-left {
				padding-right: 8px;
			}
			&.navbar-right {
				padding-top: 0;
			}
		}
	}
}

@media(max-width: 800px){

	#trigger-leftbar{
		a{margin-left: 8px!important; margin-right: 0!important;}
	} 
	#trigger-infobar a{margin-left: 0!important;}

	#topnav {
		.navbar-header{
			.navbar-brand{
				//width: 92px;
			}
		}
		.nav.navbar-nav {
			&, &.navbar-right {
				padding: 0 8px;
			}
			&.navbar-right {
				padding-right: 24px;
				margin-right: -24px;
				.dropdown-menu:before {
					right: auto;
					left: 12px;
					top: 40px;
				}
			}
			&.navbar-left {
				padding-right: 8px;
			}

			> li {
				.dropdown-menu {
					background: #fff !important;
					box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
					margin-bottom: 8px;
					.scroll-pane > .scroll-content{
					    overflow-y: auto!important;
					    position: relative!important;
					}
				}
				> a {
					span.badge, span.label {
						float: right;
						top: 0;
						right: 0;
						//margin: 4px;
						position: absolute;
					}
					span.text {float: left;}
					span.avatar{
						margin: -4px;
					}
				}
			}
		}
	}


	//#tectonic-topnav-collapsible{clear: both!important;position: relative;}
	.nav.navbar-nav{margin-top: 0px !important;}
	.navbar-right,.navbar-collapse{border-top: 0!important;}
	
	#sample-dropdown .dropdown-menu a{line-height: 16px!important;padding: 8px !important;}
	.navbar-left .dropdown-menu::before{
	    margin-left: 12px !important; margin-top: -16px !important; position: absolute; left: auto; top: auto;
	}
	#widget-tabbeddropdown .dropdown-menu::before{
	    margin-top: -8px !important;
	}
	.yamm #megamenu {
		&, > li {
			width: auto!important;
		}
	}
} 

@media (max-width: @grid-float-breakpoint-max) {
// Dropdowns get custom display when collapsed
	.open .dropdown-menu {
		float: none;
		width: auto;
		margin-top: 0;
		background-color: #fff;
		box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
		border: 0;
		width: 100%;
		> li > a {
			line-height: 16px !important;
			&:hover,
			&:focus {
				background-image: none;
			}
		}
	}
}

@media(max-width: @screen-xs-max) {
	#tectonic-topnav-collapsible{
		clear:both;
		height: 400px!important;
	    overflow-y: scroll;
	    -webkit-transition: all .25s ease;
       	-moz-transition: all .25s ease;
        -ms-transition: all .25s ease;
        -o-transition: all .25s ease;
        transition: all .25s ease;
	}
	#topnav {
		.navbar-header {
			display: inline-block;
			float: left;
			.navbar-brand {
				padding: 0 16px !important;
				left: 50%;
				margin-left: -59.25px;
			    padding: 0 16px !important;
			    position: absolute;
				.img-white{display: block;}
				.img-dark{display: none;}
			}
		}
		.nav.navbar-nav {
			&, &.navbar-right {
				padding: 0 8px;
			}
			&.navbar-right {
				float: none;
				padding-right: 0px;
				margin-right: -8px;
				margin-top: -8px !important;
				.dropdown-menu:before {
					right: auto;
					left: 12px;
					top: 40px;
				}
			}
			&.navbar-left {
				float: none;
				padding-top: 56px;
				padding-right: 8px;
				padding-top: 8px;
			}

			> li {
				.dropdown-menu {
					background: #fff !important;
					box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
					margin-bottom: 8px;
				}
				> a {
					margin-right: 0;
					span.badge, span.label {
						top: 12px;
						right: 12px;
					}
					span.text {float: left;}
					span.avatar{
						margin: -4px;
					}
				}
			}
		}
		#toolbar-search{
			display: none;
		    left: 8px;
		    position: absolute;
		    right: 8px;
		    top: 0;
		    margin-right: 0;
		}
	}
}

.select_local{
	i{
		display: none!important;
	}
}
.active{
	.select_local{
		background-color: transparent!important;
		font-weight: 600;
		position: relative;
		i{
			right: 8px;
			top: 8px;
			display: block!important;
		}
	}
}

@media(max-width: 360px){
	#tectonic-topnav-collapsible{
		clear:both;
	    overflow-y: scroll;
	    -webkit-transition: all .25s ease;
       	-moz-transition: all .25s ease;
        -ms-transition: all .25s ease;
        -o-transition: all .25s ease;
        transition: all .25s ease;
	}
}

.dropdown-menu .tab-content {
	padding: 0;
	max-height: 320px;
	overflow-x: auto;
}
