//
// Print
// --------------------------------------------------

@media print {

    .panel-collapse {
        display: block !important;
        visibility: visible;
    }

    .page-tabs {
        display:none !important;
    }

    .pagination {
        display:none !important;
    }

    .list-group-item {
        border: none !important;
    }

    .label {
        font-weight: normal !important;
        color: #000 !important;
        text-transform: lowercase !important;
        font-size: 100% !important;
        border: none !important;
    }

    .label-info {
        background-color: none !important;
    }

    .hide-for-print {
        display:none !important;
    }

    h4 {
        font-weight: 700;
    }

    h4 span.text {
        font-weight: 400;
    }
}
